function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {
        $('.close-button').on('click', function () {
            $('.attention-block').css('display', 'none');
            if (!$.cookie('notice')) {
                $.cookie('notice', true, {
                    expires: 1,
                    path: '/'
                });
            }
        });
    });
})(jQuery);
